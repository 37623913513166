import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const RefreshTokenKey = 'Admin-Refresh-Token'

const ExpiresInKey = 'Admin-Expires-In'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getRefreshToken () {
  return Cookies.get(RefreshTokenKey) || ``
}

export function setRefreshToken (token) {
  return Cookies.set(RefreshTokenKey, token)
}

export function removeRefreshToken () {
  return Cookies.remove(RefreshTokenKey)
}

export function getExpiresIn () {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn (time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn () {
  return Cookies.remove(ExpiresInKey)
}


export function setLoadingUpdate (status) {
  console.log(window);
  this.$root.loadingUpdate = status
}