<template>
  <div class="navbar">
    <hamburger id="hamburger-container"
               :is-active="sidebar.opened"
               class="hamburger-container"
               @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container"
                class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <search id="header-search"
                class="right-menu-item" />

        <!-- <el-tooltip content="源码地址" effect="dark" placement="bottom">
          <ruo-yi-git id="ruoyi-git" class="right-menu-item hover-effect" />
        </el-tooltip>

        <el-tooltip content="文档地址" effect="dark" placement="bottom">
          <ruo-yi-doc id="ruoyi-doc" class="right-menu-item hover-effect" />
        </el-tooltip>-->

        <screenfull id="screenfull"
                    class="right-menu-item hover-effect" />

        <el-tooltip content="布局大小"
                    effect="dark"
                    placement="bottom">
          <size-select id="size-select"
                       class="right-menu-item hover-effect" />
        </el-tooltip>
        <div class="balance_box"
             v-if="moneyShow">余额：{{allBalance}}</div>
      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect"
                   trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar"
               class="user-avatar" />
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="setting = true">
            <span>布局设置</span>
          </el-dropdown-item>
          <el-dropdown-item divided
                            @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import Search from "@/components/HeaderSearch";
import RuoYiGit from "@/components/RuoYi/Git";
import RuoYiDoc from "@/components/RuoYi/Doc";
import { getBalance } from "@/api/system/user";
import * as math from 'mathjs'
import { openWebSocket, closeWebScoket } from "@/utils/webSocket_mqtt.js";
export default {
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    RuoYiGit,
    RuoYiDoc,
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "device", "userId"]),
    setting: {
      get () {
        return this.$store.state.settings.showSettings;
      },
      set (val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "showSettings",
          value: val,
        });
      },
    },
  },
  data () {
    return {
      moneyShow: false,
      allBalance: localStorage.getItem("allBalance"),
      balance: localStorage.getItem("balance"),
      lockBalance: localStorage.getItem("lockBalance"),
      cashPledge: localStorage.getItem("cashPledge"),
    };
  },
  created () {
    this.getMoney();
    setInterval(() => {
      this.getMoney();
    }, 60000);
  },
  methods: {
    printFn (value) {
      const precision = 14
      return Number(math.format(value, precision))
    },
    getMoney () {
      getBalance().then((res) => {
        if (res.balance != null) {
          this.allBalance = this.printFn(res.balance.balance + res.balance.lockBalance);
          this.balance = res.balance.balance;
          this.lockBalance = res.balance.lockBalance;
          this.cashPledge = res.balance.cashPledge;
          localStorage.setItem(
            "allBalance",
            this.allBalance
          ); // 总余额
          localStorage.setItem("balance", res.balance.balance); // 可用余额
          localStorage.setItem("lockBalance", res.balance.lockBalance); // 锁定金额
          localStorage.setItem("cashPledge", res.balance.cashPledge); // 授信金额
          this.moneyShow = true;
        } else {
          this.moneyShow = false;
        }
        localStorage.setItem("moneyShow", this.moneyShow); // 是否显示
      });
    },
    toggleSideBar () {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout () {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          // closeWebScoket(this.$root.clientMqtt);
          location.href = "/index";
        });

      });
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
.balance_box {
  display: inline-block;
  height: 100%;
  line-height: 50px;
  vertical-align: top;
}
</style>
