import request from '@/utils/request'
import { praseStrEmpty } from "@/utils/ruoyi";

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/system/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUser(userId) {
  return request({
    url: '/system/user/' + praseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/system/user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser(data) {
  return request({
    url: '/system/user',
    method: 'put',
    data: data
  })
}


// 删除用户
export function delUser(userId) {
  return request({
    url: '/system/user/' + userId,
    method: 'delete'
  })
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password
  }
  return request({
    url: '/system/user/resetPwd',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/system/user/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data: data
  })
}



// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}


// 实名认证
export function updateReal(data) {
  return request({
    url: '/system/user/profile/real',
    method: 'put',
    data: data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  })
}
// 获取用户余额
export function getBalance() {
  return request({
    url: '/system/login/getBalance',
    method: 'get',
  })
}
// 支付接口
export function getPayQr(params) {
    return request({
      url: '/system/prdPayOrder/getPayQr',
      method: 'get',
      params: params
    })

}
// 查看支付信息
export function getPayStatusInfo(params) {
  return request({
    url: '/system/prdPayOrder/getPayStatusInfo',
    method: 'get',
    params: params
  })
}


// 提现接口
export function toApply(params, roleGroup) {
  if (roleGroup == "代理商") {
    return request({
      url: '/agent/prdAgentDrawCash/toApply',
      method: 'get',
      params: params
    })
  }
  else {
    return request({
      url: '/product/prdSupDrawCash/toApply',
      method: 'get',
      params: params
    })
  }

}


// 获取绑定二维码
export function getQRBarcodeURL(params) {
  return request({
    url: '/system/user/profile/getQRBarcodeURL',
    method: 'get',
    params: params
  })
}

// 获取绑定二维码
export function bindAuthenticatorCode(params) {
  return request({
    url: '/system/user/profile/bindAuthenticatorCode',
    method: 'get',
    params: params
  })
}


// 获取验证码
export function getSms (query) {
  return request({
    url: '/system/login/getSms',
    method: 'get',
    params: query
  })
}


// 获取验证码
export function UpdateBindPhone (query) {
  return request({
    url: '/system/user/profile/UpdateBindPhone',
    method: 'get',
    params: query
  })
}