const dfilters = {
    nametype: function(e) {
        if(e){
           return "***************" + e.substring(12)
          }
    },
    namelength: function(e) {
        if(e){
          if(e.length >= 3){
            return '**' + e.substring(2)
          }else{
            return '*' + e.substring(1)
          }
        }
    }
}
export default dfilters;