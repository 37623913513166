import request from '@/utils/request'

const client_id = 'web'
const client_secret = '123456'
const scope = 'server'

// 登录方法1
export function login(username, password, authenticatorCode, code, uuid) {
  const grant_type = 'password'
  if (window.document.domain.startsWith("system") && username.indexOf("@") < 0) {
    username = username + "@sys"
  }
  else if (window.document.domain.startsWith("supplier") && username.indexOf("@") < 0) {
    username = username + "@sup"
  }
  else {
    if (username.indexOf("@") < 0) {
      username = username + "@agent"
    }
  }
  return request({
    url: '/auth/oauth/token',
    method: 'post',
    params: { username, password, authenticatorCode, code, uuid, client_id, client_secret, grant_type, scope }
  })
}

// 刷新方法
export function refreshToken(refresh_token) {
  const grant_type = 'refresh_token'
  return request({
    url: '/auth/oauth/token',
    method: 'post',
    params: { client_id, client_secret, grant_type, scope, refresh_token }
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/login/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    method: 'get'
  })
}
