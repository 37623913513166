<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title" />
  </div>
</template>

<script>
import { getWxOpenId } from "@/utils/isWeiXin.js";
import { getSysTime } from "@/utils/payment.js";
import { openWebSocket, closeWebScoket } from "@/utils/webSocket_mqtt.js";
export default {
  name: "App",
  data() {
    return {
      
    };
  },
  created() {
    if (!localStorage.getItem("notifcationCheckBox")) {
      localStorage.setItem(
        "notifcationCheckBox",
        JSON.stringify(["异常", "财务", "库存"])
      );
    }
    this.initPage();
    this.getSysTime();
  },
  methods: {
    //  获取payType
    initPage() {
      // getWxOpenId();
      // setInterval(() => {
      //   var clientId = localStorage.getItem("clientId");
      //   var topic = localStorage.getItem("topic");
      //   if (this.$root.clientMqtt==null) {
      //     //client为空 clientId && topic 不为空时开启
      //     if (clientId && topic) {
      //       this.$root.clientMqtt = openWebSocket(
      //         clientId + "@" + topic,
      //         topic
      //       );
      //     }
      //   } else if (this.$root.clientMqtt!=null) {
      //     //client不为空 clientId && topic 为空时关闭
      //     if (!clientId && !topic) {
      //       this.$root.clientMqtt = closeWebScoket(this.$root.clientMqtt);
      //     }
      //   }
      // }, 3000);
    },
    //  获取payType
    getSysTime() {
      let nowTime = new Date().getTime();
      getSysTime().then((response) => {
        this.$root.sysTime = nowTime - parseInt(response.data);
      });
    },
  },
};
</script>
<style >
</style>
