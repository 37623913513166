import request from '@/utils/request'


// 获取系统时间
export function getSysTime() {
  return request({
    url: '/system/login/getTime',
    method: 'get'
  })
}

// 获取系统时间
export function getElapsedTime(status, sysTime, createTime, updateTime) {
  let createTimestamp = new Date(createTime.replace(/\-/g, "/")).getTime();
  let updateTimestamp = 0;
  if (updateTime) {
    updateTimestamp = new Date(updateTime.replace(/\-/g, "/")).getTime()
  }
  let nowTimeStamp = new Date().getTime();
  if (status <= 10) {
    return timezh((nowTimeStamp - sysTime - createTimestamp) / 1000);
  }
  else {
    return timezh((updateTimestamp - createTimestamp) / 1000);
  }
}

// 获取剩余时间
export function getSurplusTime(status, sysTime, orderTimeOutDate) {
  if (orderTimeOutDate == null) {
    return '0';
  }
  let timeOutTimestamp = new Date(orderTimeOutDate.replace(/\-/g, "/")).getTime();
  let nowTimeStamp = new Date().getTime();
  if (status <= 10) {
    let timeStameDiff = (timeOutTimestamp - nowTimeStamp - sysTime) / 1000;
    if (timeStameDiff < 0) {
      return '-' + timezh(timeStameDiff * -1);
    }
    else {
      return '' + timezh(timeStameDiff);
    }

  }
  else {
    return '0';
  }

}


// 添加日期范围
export function addDateRangeList(params, dateRange) {
  var search = params;
  for (var item in dateRange) {
    //console.log(item);
    search[item] = dateRange[item];
  }
  return search;
}

// 获取默认宽度
export function geColumnWidth(columnWidth) {
  return columnWidth != null ? columnWidth : 1;
}

export function timezh(value) {
  var theTime = parseInt(value); // 需要转换的时间秒
  var theTime1 = 0; // 分
  var theTime2 = 0; // 小时
  var theTime3 = 0; // 天
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
      if (theTime2 > 24) {
        // 大于24小时
        theTime3 = parseInt(theTime2 / 24);
        theTime2 = parseInt(theTime2 % 24);
      }
    }
  }
  if (theTime3 > 0) {
    return "" + parseInt(theTime3) + "天" + parseInt(theTime2) + "时";
  }
  else if (theTime2 > 0) {
    return "" + parseInt(theTime2) + "时" + parseInt(theTime1) + "分";
  }
  else if (theTime1 > 0) {
    return "" + parseInt(theTime1) + "分" + parseInt(theTime) + "秒";
  }
  else {
    return "" + parseInt(theTime) + "秒";
  }

}


export function endTime(enTime) {
  const self = this
  // if (this.queryParams.beginUpdateTime && this.queryParams.beginUpdateTime != null) {
  return {
    disabledDate(time) {
      if (enTime) {  //如果开始时间不为空，则结束时间大于开始时间
        return new Date(enTime).getTime() > time.getTime()
      } else {
        // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
      }
    }
  }
  // }
}


export function getChangeTime(stamp) {
  // 判断是不是13位的时间戳格式
  // if (stamp.length < 13) {
  // 	stamp = stamp * 1000
  // } else {
  // 	stamp = stamp
  // }
  let date = new Date(Number(stamp))
  // 获取年
  let year = date.getFullYear()

  // 获取月(月份为单位数前边补0)
  let month = date.getMonth() + 1

  // 获取日
  let day = date.getDate()

  // 获取时

  let hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
  // 获取分

  let minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
  // 获取秒

  let second = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()

  // return year + '-' + month + '-' + day + ' ' + hour + ':' + minute +':' + second
  return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
}

export function getChangeTimeDay(stamp) {
  // 判断是不是13位的时间戳格式
  // if (stamp.length < 13) {
  // 	stamp = stamp * 1000
  // } else {
  // 	stamp = stamp
  // }
  let date = new Date(Number(stamp))
  // 获取年
  let year = date.getFullYear()

  // 获取月(月份为单位数前边补0)
  let month = date.getMonth() + 1

  // 获取日
  let day = date.getDate()

  // 获取时

  let hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours()
  // 获取分

  let minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()
  // 获取秒

  // let second = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds()

  // return year + '-' + month + '-' + day + ' ' + hour + ':' + minute +':' + second
  return year + '-' + month + '-' + day
}