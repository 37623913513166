import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken, setLoadingUpdate } from "@/utils/auth";
import errorCode from "@/utils/errorCode";
import { tansParams } from "@/utils/ruoyi";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (config.url != "/system/login/getBalance") {
      store.dispatch("app/setLoadingUpdate", true);
    }

    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    // get请求映射params参数
    let beginTimeReg = RegExp(/begin.*Time/);
    let endTimeReg = RegExp(/end.*Time/);
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";

        if (value !== null && typeof value !== "undefined") {
          if (beginTimeReg.exec(propName) || endTimeReg.exec(propName)) {
            let params = "params[" + propName + "]";
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value) + "&";
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    if (config.url != "/system/login/getBalance") {
      store.dispatch("app/setLoadingUpdate", false);
    }

    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    if (res.config.url != "/system/login/getBalance") {
      store.dispatch("app/setLoadingUpdate", false);
    }
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    if (code === 401) {
      MessageBox.confirm(
        "登录状态已过期，您可以继续留在该页面，或者重新登录",
        "系统提示",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        store.dispatch("LogOut").then(() => {
          location.href = "/index";
        });
      });
    } else if (code === 500) {
      // Message({
      //   message: msg,
      //   type: 'error'
      // })
      Notification.error({
        title: msg,
      });
      return Promise.reject(new Error(msg));
    } else if (code === 300) {
      return res.data;
    } else if (code !== 200) {
      Notification.warning({
        title: msg,
      });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    // if (res.config.url != '/system/login/getBalance') {
    //   store.dispatch('app/setLoadingUpdate', false)
    // }
    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

// 通用下载方法
export function download(url, params, filename) {
  this.$root.loadingExport = true;
  return service
    .post(url, params, {
      transformRequest: [
        (params) => {
          return tansParams(params);
        },
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      responseType: "blob",
      timeout: 300000,
    })
    .then((data) => {
      const content = data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, filename);
      }
      this.$root.loadingExport = false;
    })
    .catch((r) => {
      console.error(r);
      this.$root.loadingExport = false;
    });
}

export default service;
