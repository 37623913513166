<template>
  <div class="top-right-btn">
    <el-row>
      <el-tooltip class="item"
                  effect="dark"
                  content="帮助"
                  placement="top">
        <el-button size="mini"
                   circle
                   icon="el-icon-headset"
                   @click="showHelp()" />
      </el-tooltip>
      <el-tooltip class="item"
                  effect="dark"
                  :content="showSearch ? '隐藏搜索' : '显示搜索'"
                  placement="top">
        <el-button size="mini"
                   circle
                   icon="el-icon-search"
                   @click="toggleSearch()" />
      </el-tooltip>
      <el-tooltip class="item"
                  effect="dark"
                  content="刷新"
                  placement="top">
        <el-button size="mini"
                   circle
                   icon="el-icon-refresh"
                   @click="refresh()" />
      </el-tooltip>
    </el-row>
  </div>
</template>
<script>
import { getMenu, getHelpShow, addHelpShow } from "@/api/system/menu";
import { MessageBox } from "element-ui";
export default {
  name: "RightToolbar",
  data() {
    return {
      helpContent: null,
      menuId: null,
    };
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.getHelp();
  },
  methods: {
    //获取帮助内容
    getHelp() {
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );

      this.menuId = matched[matched.length - 1].meta.menuId;
      if (this.menuId) {
        getMenu(this.menuId).then((response) => {
          if (
            response.data.helpContent &&
            response.data.helpContent.length > 15
          ) {
            //这里判断是否需要弹窗
            //这里绑定帮助内容
            this.helpContent = response.data.helpContent;
            getHelpShow(this.menuId).then((response) => {
              if (response.code == "200") {
                this.showHelp();
              }
            });
          }
        });
      }
    },
    showHelp() {
      MessageBox.confirm(this.helpContent, "帮助内容", {
        confirmButtonText: "已阅读",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        //添加已阅读属性
        addHelpShow(this.menuId).then((response) => { });
      });
    },
    //搜索
    toggleSearch() {
      this.$emit("update:showSearch", !this.showSearch);
    },
    //刷新
    refresh() {
      this.$emit("queryTable");
    },
  },
};
</script>