var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          ref: "TrendsDialog",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.visible,
            width: _vm.width,
            "before-close": _vm.handleClose,
            close: _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm._t("default"),
          _c("div", { staticClass: "dialog-footer" }, [_vm._t("footer")], 2),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }