<!-- create by crystalSong 分页+table+动态设置表格列的隐藏与显示 -->
<template>
  <div class="trends_container">
    <div class="table_container">
      <el-table
        empty-text="暂无数据"
        :ref="refTable"
        :data="tableList"
        :show-summary="showSummary"
        :summary-method="getSummaries"
        lazy
        :row-key="rowKey"
        :load="load"
        show-overflow-tooltip
        :row-class-name="rowClassName"
        @selection-change="handleSelectionChange"
        @expand-change="expandSelect"
        @row-dblclick="dbclick"
        @row-click="rowClick"
      >
        <slot></slot>
      </el-table>
    </div>
    <div class="pagination_trends_wrap">
      <div class="trends_oprt_wrap">
        <el-popover placement="top-start" width="630" trigger="click">
          <div class="trends_btn_wrap">
            <el-checkbox-group
              v-model="visibleList"
              size="small"
              @change="visibleListChange"
            >
              <el-checkbox
                style="width: 130px"
                v-for="(col, index) in columnList"
                :key="index"
                :label="col.label"
                border
                >{{ col.value }}</el-checkbox
              >
            </el-checkbox-group>
          </div>

          <el-button slot="reference" size="small">隐藏/显示列</el-button>
        </el-popover>
      </div>

      <div class="trends_oprt_page">
        <el-switch
          size="small"
          active-text="长"
          inactive-text="短"
          v-model="currentLongPage"
          @change="saveLongPage"
        ></el-switch>
      </div>
      <div
        class="pagination_wrap"
        v-if="total > 0  && currentLongPage"
      >
        <template>
          <el-pagination
            style="text-align: right"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 15, 20, 25, 50, 100, 200, 300, 500, 1000]"
            :page-size.sync="currentSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          ></el-pagination>
        </template>
      </div>
      <div class="pagination_wrap" v-if="!currentLongPage">
        <template>
          <el-pagination
            style="text-align: right"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[10, 15, 20, 25, 50, 100, 200, 500, 1000]"
            :page-size.sync="currentSize"
            layout=" prev, next,sizes, jumper"
            background
          ></el-pagination>
        </template>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      title="详情"
      :visible.sync="dialogVisible"
      width="1024px"
      append-to-body
    >
      <el-row v-for="(item1, index1) in this.details" :key="index1">
        <el-form label-position="right" label-width="130px">
          <el-col :span="8" v-for="(item2, index2) in item1" :key="index2">
            <el-form-item :label="item2.name + ':'">{{
              item2.value
            }}</el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "trendsTable",
  props: {
    fatherData: {
      type: Object,
      default: () => {},
    },
    treeProps: {
      type: Object,
      default: () => {},
    },
    load: {
      type: Function,
    },
    rowClassName: {
      type: Function,
    },
    rowKey: {
      type: String,
    },
    refTable: {
      type: String,
      default: "trendsTable",
    },
    tableList: {
      type: Array,
      require: true,
      default: () => [],
    },
    showSummary: {
      type: Boolean,
      require: true,
    },
    getSummaries: {
      type: Function,
      require: true,
    },
    hideColumnIndexs: {},
    pageSize: {
      type: Number,
      default: 15,
    },
    pageNumber: {
      type: Number,
      default: 1,
    },
    total: {
      required: true,
      type: Number,
      default: 0,
    },
    longPage: {
      type: Boolean,
      default: false,
    },
  },

  components: {},
  data() {
    return {
      dialogVisible: false, //详情页显示
      visibleList: [], //显示/隐藏列的选中下标数据集合
      columnList: [], //表格所有列名称数据列表
      details: [], //详情数据
      ietms: [],
    };
  },
  created() {
    // console.log(this.longPage);
    // console.log(this.currentLongPage);
  },
  mounted() {
    let _this = this;
    let curHideList = {};
    this.$refs[this.refTable].$children.forEach((obj, index) => {
      if (obj.type) {
        let keyName = obj.prop;
        if (obj.type != "selection") {
          if (obj.label == "操作") {
            keyName = "operation";
          }
          _this.columnList.push({
            label: keyName,
            value: obj.type == "selection" ? "选择" : obj.label,
          });
          // 记录初始展示的列
          if (!_this.hideColumnIndexs.hasOwnProperty(keyName)) {
            let localStorageName = window.location.pathname +"/"+ keyName;
            if (localStorage.getItem(localStorageName)) {
              let showFlag = localStorage.getItem(localStorageName) == "true";
              if (!showFlag) {
                _this.visibleList.push(keyName);
                curHideList[keyName] = showFlag;
              } else {
                curHideList[keyName] = showFlag;
              }
            } else {
              _this.visibleList.push(keyName);
              curHideList[keyName] = false;
              localStorage.setItem(localStorageName, false);
            }
          } else {
            curHideList[keyName] = true;
            localStorage.setItem(localStorageName, true);
          }
        }
      }
    });

    _this.$emit("getHideColist", curHideList);
  },
  methods: {
    saveLongPage(val) {
      this.currentLongPage = val;
      localStorage.setItem("longPage", val);
      this.$emit("pagination", { longPage: val });
    },
    handleSizeChange(val) {
      localStorage.setItem("pageSize", val);
      this.$emit("pagination", { pageNumber: 1, pageSize: val });
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { pageNumber: val, pageSize: this.currentSize });
    },
    visibleListChange(item) {
      let curHideList = {};
      this.columnList.forEach((obj, index) => {
        let showFlag = false;
        // 更改显示隐藏列
        if (item.indexOf(obj.label) == -1) {
          showFlag = true;
        }

        curHideList[obj.label] = showFlag;
        let localStorageName = window.location.pathname +"/"+ obj.label;
        localStorage.setItem(localStorageName, showFlag);
      });
      this.$emit("getHideColist", curHideList);
    },
    handleSelectionChange(item) {
      this.$emit("selection-change", item);
    },
    expandSelect(item) {
      this.$emit("expand-change", item);
    },

    getDictLabel(row, columnName) {
      let value = row[columnName];
      this.$refs[this.refTable].$children.forEach((obj, index) => {
        if (obj.formatter) {
          if (columnName == obj.prop) {
            let optionsObj = this.$parent[obj.prop + "Options"];
            if (!optionsObj) {
              optionsObj = this.$parent.$parent[obj.prop + "Options"];
            }
            //   console.log(this.$parent);
            // console.log(this.$parent['cardStatusOptions']);
            // console.log(columnName, this.$parent[obj.prop + "Options"]);
            value = this.selectDictLabel(optionsObj, row[obj.prop]);
          }
        }
      });
      return value;
    },

    //监控双击弹出详情
    dbclick(row, column, cell, event) {
      let detailsItem = new Array();
      this.details = new Array();
      let itemIndex = 0;
      this.columnList.forEach((obj, index) => {
        if (obj.label) {
          if (this.visibleList.indexOf(obj.label) != -1) {
            detailsItem.push({
              name: obj.value,
              value: this.getDictLabel(row, obj.label),
            });
            itemIndex++;
            if (itemIndex % 3 == 0) {
              this.details.push(detailsItem);
              detailsItem = new Array();
            }
          }
        }
      });
      this.dialogVisible = true;
    },
    // 点击行 选中当前行
    rowClick(row, column, event) {
      let val = [];
      val.push(row);
      if (val) {
        val.forEach((row) => {
          this.$refs[this.refTable].toggleRowSelection(row);
        });
      } else {
        this.$refs[this.refTable].clearSelection();
      }
    },
    // 取消按钮
    cancel() {
      this.dialogVisible = false;
    },
    /**
     * 遍历列的所有内容，获取最宽一列的宽度
     * @param arr
     */
    getMaxLength(arr) {
      return arr.reduce((acc, item) => {
        if (item) {
          let calcLen = this.getTextWidth(item);
          if (acc < calcLen) {
            acc = calcLen;
          }
        }
        return acc;
      }, 0);
    },
    /**
     * 使用span标签包裹内容，然后计算span的宽度 width： px
     * @param valArr
     */
    getTextWidth(str) {
      let width = 0;
      let html = document.createElement("span");
      html.innerText = str;
      html.className = "getTextWidth";
      document.querySelector("body").appendChild(html);
      width = document.querySelector(".getTextWidth").offsetWidth;
      document.querySelector(".getTextWidth").remove();
      return width;
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.pageNumber;
      },
      set(val) {
        this.$emit("update:pageNumber", val);
      },
    },
    currentSize: {
      get() {
        return this.pageSize;
      },
      set(val) {
        this.$emit("update:pageSize", val);
      },
    },
    currentLongPage: {
      get() {
        return this.longPage;
      },
      set(val) {
        this.$emit("update:longPage", val);
      },
    },
  },
  watch: {
    //	监听data中的数据，无效
    longPage: {
      //	即使用深度监听，还是可以发现并没有效果
      handler(newVal, oldVal) {
        if (!newVal) {
          if (
            localStorage.getItem("longPage") &&
            localStorage.getItem("longPage") == "true"
          ) {
            this.$emit("update:longPage", true);
          } else {
            this.$emit("update:longPage", false);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    /**
     * 监控表格的数据data，自动设置表格宽度
     * @param valArr
     */
    tableList(valArr) {
      let curWidthList = {};
      this.columnList.forEach((obj, index) => {
        const arr = valArr.map((x) => x[obj.label]); // 获取每一列的所有数据
        let maxStr = obj.value;
        let maxLength = maxStr.length;
        if (escape(maxStr).indexOf("%u") >= 0) {
          maxLength = maxStr.length * 2;
        }
        arr.forEach(function (value, key) {
          if (value != null) {
            let nowLength = value.length;
            if (escape(value).indexOf("%u") >= 0) {
              nowLength = value.length * 2;
            }

            if (maxLength < nowLength) {
              maxStr = value;
              maxLength = nowLength;
            }
          }
        

        });

        // 每列内容最大的宽度 + 表格的内间距(依据实际情况而定)
        let textLength = this.getTextWidth(maxStr);
        let maxTextLength = textLength * 1.5;
        //console.log(maxStr, maxLength, textLength, maxTextLength);
        if (maxTextLength > 400) {
          maxTextLength = 300;
        }
        if (maxTextLength < 90) {
          maxTextLength = 90;
        }
        curWidthList[obj.label] = maxTextLength;
        //console.log(obj.label,maxTextLength);
      });

      this.$emit("getWidthColist", curWidthList);
    },
  },
};
</script>
<style lang='less' scoped>
.trends_container {
  .pagination_trends_wrap {
    margin: 20px 0;
    position: relative;
  }
  .trends_oprt_wrap {
    display: inline-block;
    vertical-align: top;
    width: 100px;
  }
  .trends_oprt_page {
    display: inline-block;
    vertical-align: sub;
    width: 150px;
  }
  .pagination_wrap {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 255px);
    margin: 0;
  }
}
.el-row {
  margin-bottom: 0px;
}
.el-form-item {
  margin-bottom: 0px;
}
</style>
<style lang="less">
.trends_btn_wrap {
  .el-checkbox-group {
    label {
      margin: 0;
      margin: 0 10px;
      margin-bottom: 15px;
    }
  }
}
.redC {
  color: red;
}
.greenC {
  color: green;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table .danger-row {
  background: #ffe1e1;
}
</style>
