import getParam from "@/utils/getParam.js";
import request from '@/utils/request'
import { login } from "../api/login";
// 判断是否是微信浏览器
let payType = ''
let openid = '' || localStorage.getItem('openid')
export function getWxOpenId() {

  if (localStorage.getItem("openid")) {
    //已有openId不再重复获取
    console.log('已有openId不再重复获取');
    return;
  }
  console.log('开始获取openId');
  var ua = window.navigator.userAgent.toLowerCase();
  // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
  if (ua.indexOf("micromessenger") > -1) {
    const code = getParam("code");
    const local = window.location.href;

    if (code == null || code == "") {
      let obj = {
        url: local
      }
      getWxAccessUrl(obj).then(res => {
        window.location.href = res.data
      })
    }
    else {
      payType = "JSAPI";
      localStorage.setItem('payType', "JSAPI")
      if (openid == "" || openid == null || openid == undefined || openid == "undefined") {
        pay_m2(code);
      }
    }


  } else {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      payType = "MWEB";
      localStorage.setItem('payType', "MWEB")
    } else {
      payType = "PC";
      localStorage.setItem('payType', "PC")
    }
  }

}
//  获取code 调用
function pay_m2(code) {
  const local = window.location.href;
  let obj = {
    url: local,
    code: code,
  };
  getWxAccessToken(obj).then((res) => {
    console.log(res);
    localStorage.setItem('openid', res.data.openid)
    console.log('openId:' + localStorage.getItem("openid"));
  });
}

// 获取微信AccessToken
function getWxAccessToken(params) {
  return request({
    url: '/agent/prdSpread/getWxAccessToken',
    method: 'get',
    params: params
  })
}


// 获取微信CodeUrl
function getWxAccessUrl(params) {
  return request({
    url: '/agent/prdSpread/getWxAccessUrl',
    method: 'get',
    params: params
  })
}



//解决微信内置对象报错 ---  点击支付时调用
export function weixinPay(params) {
  var that = this;
  if (typeof WeixinJSBridge == "undefined") {
    if (document.addEventListener) {
      document.addEventListener(
        "WeixinJSBridgeReady",
        that.onBridgeReady(params),
        false
      );
    } else if (document.attachEvent) {
      document.attachEvent(
        "WeixinJSBridgeReady",
        onBridgeReady(params)
      );
      document.attachEvent(
        "onWeixinJSBridgeReady",
        onBridgeReady(params)
      );
    }
  } else {
    onBridgeReady(params);
  }
}
//微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
function onBridgeReady(params) {
  var that = this;
  var timestamp = Math.round(params.timeStamp).toString();
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      debug: false,
      appId: params.appId, //公众号名称，由商户传入
      timeStamp: timestamp, //时间戳，自1970年以来的秒数
      nonceStr: params.nonceStr, //随机串
      package: params.packageValue,
      signType: params.signType, //微信签名方式：
      paySign: params.paySign, //微信签名
      jsApiList: ["chooseWXPay"],
    },

    function (res) {
      // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        //支付成功后的操作
        this.msgSuccess("支付成功");
        that.isDisabledSubmitBtn = true;
      } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
        //取消支付的操作
        this.msgInfo("取消支付");
        that.isDisabledSubmitBtn = false; //按钮恢复高亮
      } else {
        //支付失败的操作
        this.msgError("支付失败");
        that.isDisabledSubmitBtn = false; //按钮恢复高亮
      }
    }
  );
}
