var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view", {
        directives: [
          {
            name: "wechat-title",
            rawName: "v-wechat-title",
            value: _vm.$route.meta.title,
            expression: "$route.meta.title",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }