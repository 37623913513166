import Vue from 'vue'

import Cookies from 'js-cookie'

import Element from 'element-ui'

import Icon from 'vue-svg-icon/Icon.vue'

import './assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import '@/assets/styles/diy.scss' // ruoyi css
import App from './App'
import store from './store'
import router from './router'
import permission from './directive/permission'
import { download } from '@/utils/request'

import './assets/icons' // icon
import './permission' // permission control
import { getDicts } from "@/api/system/dict/data";
import { getDictsPath, getAgentPath } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";
import { geColumnWidth, getChangeTime, getChangeTimeDay, getElapsedTime, getSurplusTime } from "@/utils/payment";
import dfilters from './utils/filter';//全局过滤器
import Pagination from "@/components/Pagination";
//自定义表格工具扩展
import RightToolbar from "@/components/RightToolbar"
// 代码高亮插件
import hljs from 'highlight.js'
import 'highlight.js/styles/github-gist.css'
// 引入-table-组件
import TrendsTable from "@/components/TrendsTable";
// 引入-Dialog-组件
import TrendsDialog from "@/components/TrendsDialog";
// import vConsole from 'vconsole'
// var vconsole = new vConsole();
// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getDictsPath = getDictsPath
Vue.prototype.getAgentPath = getAgentPath
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.geColumnWidth = geColumnWidth
Vue.prototype.getElapsedTime = getElapsedTime
Vue.prototype.getSurplusTime = getSurplusTime
Vue.prototype.getChangeTime = getChangeTime
Vue.prototype.getChangeTimeDay = getChangeTimeDay
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}

for (let key in dfilters) {
  Vue.filter(key, dfilters[key]);
}

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('TrendsTable', TrendsTable)
Vue.component('TrendsDialog', TrendsDialog)
Vue.component('RightToolbar', RightToolbar)
Vue.component('Icon', Icon)

//修改标题
Vue.use(require('vue-wechat-title'))
Vue.use(permission)
Vue.use(hljs.vuePlugin);
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.config.productionTip = false


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  data: function () {
    return {
      sysTime: 0,
      loadingExport: false,
      loadingUpdate: false,
      clientMqtt: null,
    }
  },
})
