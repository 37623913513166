<template>
  <div class="app-container">
    <el-dialog :close-on-click-modal="false" 
      ref="TrendsDialog"
      :title="title"
      :visible.sync="visible"
      :width="width"
      :before-close="handleClose"
      :close="handleClose"
    >
      <slot></slot>
      <div class="dialog-footer">
        <slot name="footer"></slot>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TrendsDialog",
  props: {
    title: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
  },

  data() {
    return {};
  },
  created() {
    //console.log(this.$props.width);
    setTimeout(() => {
      this.setDialogWidth();
    }, 300);
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },
  methods: {
    handleClose() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },

    setDialogWidth() {
      // console.log(this.$refs.TrendsDialog.$el);
      var val = document.body.clientWidth;
      const def = 750; // 默认宽度
      if (val < def) {
        this.$refs.TrendsDialog.$el.children[0].style.width = "100%";
        //this.$props.TrendsDialog.width="100%"
        //this.width = "100%";
      } else {
        //this.$refs.TrendsDialog.$el.children[0].style.width = "50%";
        //this.width = "50%";
      }
      //console.log(this.$refs.TrendsDialog.$el.children[0]);
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  padding: 20px;
  padding-top: 10px;
  text-align: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>