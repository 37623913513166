import mqtt from "mqtt";
import CryptoJS from "crypto-js";
import { Notification } from 'element-ui';

var messageTime;
var messageNum = 0;
// export function openWebSocket( username, domainToken) {

//   let instanceId = 'post-cn-2r42g9ezo0f';//实例 ID，购买后从控制台获取
//   let host = 'post-cn-2r42g9ezo0f.mqtt.aliyuncs.com';// 设置当前用户的接入点域名，接入点获取方法请参考接入准备章节文档，先在控制台创建实例
//   let topic = 'payment_ordinary/' + domainToken;//需要操作的 Topic,第一级父级 topic 需要在控制台申请
//   let accessKey = 'LTAI5tQePsKyp3GrTaj464Xy';//账号的 AccessKey，在阿里云控制台查看
//   let secretKey = 'xs2IYxqgPPR129dkEFbh9NEt6kOOQn';//账号的的 SecretKey，在阿里云控制台查看
//   let cleansession = false;
//   let groupId = 'GID_notification';//MQTT GroupID,创建实例后从 MQTT 控制台创建
//   let reconnectTimeout = 2000;
//   let clientId = groupId + '@@@' + username;//GroupId@@@DeviceId，由控制台创建的 Group ID 和自己指定的 Device ID 组合构成
//   let usernameMtqq = 'Signature|' + accessKey + '|' + instanceId;//username和 Password 签名模式下的设置方法，参考文档 https://help.aliyun.com/document_detail/48271.html?spm=a2c4g.11186623.6.553.217831c3BSFry7
//   let passwordMtqq = CryptoJS.HmacSHA1(clientId, secretKey).toString(CryptoJS.enc.Base64);



//   const options = {
//     //1000 milliseconds, interval between two reconnections. Disable auto reconnect by setting to 0.
//     reconnectPeriod: 30000,
//     //30 * 1000 milliseconds, time to wait before a CONNACK is received
//     connectTimeout: 3000,
//     //60 seconds, set to 0 to disable
//     keepalive: 60,
//     clientId: clientId, //登录账号带@的
//     username: usernameMtqq,
//     password: passwordMtqq,
//     //true, set to false to receive QoS 1 and 2 messages while offline
//     clean: cleansession,
//   };

//   //远程连接
//   let client = mqtt.connect("wss://" + host + "/mqtt", options);


//   client.on("connect", (e) => {
//     console.log("连接成功！！！");
//     client.subscribe(topic, { qos: 1 }, (error) => {
//       if (!error) {
//         console.log("订阅成功");
//       } else {
//         console.log("订阅失败:" + error);
//       }
//     });
//   });
//   client.on("error", (error) => {
//     console.log("连接出错", error);
//   });
//   // 接收消息处理
//   client.on("message", (topic, message) => {
//     try {
//       console.log("收到来自", topic, "的消息", message.toString());
//       let messageJson = JSON.parse(message)
//       let notifcationCheckBox = JSON.parse(localStorage.getItem("notifcationCheckBox"))
//       if (messageJson.noticeTitle.indexOf("余额") > -1 && notifcationCheckBox.indexOf("财务") < 0) {
//         return false;
//       }
//       if (messageJson.noticeTitle.indexOf("异常") > -1 && notifcationCheckBox.indexOf("异常") < 0) {
//         return false;
//       }
//       if (messageJson.noticeTitle.indexOf("库存") > -1 && notifcationCheckBox.indexOf("库存") < 0) {
//         return false;
//       }
//       messageNum++;
//       if (messageNum < 5) {
//         Notification({
//           title: messageJson.noticeTitle + '  (ESC键关闭)',
//           dangerouslyUseHTMLString: true,
//           message: messageJson.noticeContent + "<br>时间：" + messageJson.createTime,
//           type: 'warning',
//           duration: 30000 //不自动关闭
//         });
//       }
//       var url = "https://tts.baidu.com/text2audio?cuid=baike&lan=ZH&ctp=1&pdt=301&vol=15&per=5&tex=" + encodeURI(messageJson.noticeTitle);
//       //三秒只触发一次语音，避免一次发送语音
//       if (!messageTime || new Date().getTime() > new Date(messageTime).getTime() + 3000) {
//         //console.log(messageTime);
//         messageTime = new Date();
//         messageNum = 0;
//         new Audio(url).play();
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   });
//   return client;
// }

// export function sendMessage(client, topic, message) {
//   client.publish(topic, message);
//   return client;
// }

// export function closeWebScoket(client) {
//   console.log("断开连接");
//   //连接断开
//   client.end();
//   client = null;
//   return client;
// }